import { CircularProgress } from '@mui/material'
import React from 'react'

export default function AccountLoading({ noMinHeight }) {
  return (
    <div className='loading' style={{ minHeight: noMinHeight ? '0' : '528px', width: '100%', flex: 1, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <CircularProgress className='progress circular' />
    </div>
    )
}
