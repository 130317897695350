import { APIProvider } from "./context/APIContext";

function App({ children }) {
  return (
    <APIProvider>
      {children}
    </APIProvider>
  );
}

export default App;
