import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

export default function Redirect() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        navigate(`name${location.search}`);
    }, [location, navigate])

    return null;
}
