import React, { useContext, useState } from 'react'
import AccountBanner from '../../../../components/AccountBanner'
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { AuthContext } from '../../../../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';

export default function BasicInfo() {
    const { signUp, setSignUp } = useContext(AuthContext);
    
    const navigate = useNavigate();
    const location = useLocation();

    const [ day, setDay ] = useState(signUp.birthdate !== '' ? new Date(signUp.birthdate).getUTCDate() : '');
    const [ month, setMonth ] = useState(signUp.birthdate !== '' ? (new Date(signUp.birthdate).getUTCMonth() + 1).toString() : '');
    const [ year, setYear ] = useState(signUp.birthdate !== '' ? (new Date(signUp.birthdate).getUTCFullYear()).toString() : '');

    const [ gender, setGender ] = useState(signUp.gender);

    const [ loading, setLoading ] = useState(false);

    const [ error, setError ] = useState('');
    const [ genderError, setGenderError ] = useState('');

    const genders = [
        { value: '1', label: 'Masculino' },
        { value: '2', label: 'Femenino' },
        { value: '3', label: 'Boeing AH-64 Apache' },
    ];

    const months = [
        { value: '1', label: 'Enero' },
        { value: '2', label: 'Febrero' },
        { value: '3', label: 'Marzo' },
        { value: '4', label: 'Abril' },
        { value: '5', label: 'Mayo' },
        { value: '6', label: 'Junio' },
        { value: '7', label: 'Julio' },
        { value: '8', label: 'Augosto' },
        { value: '9', label: 'Septiembre' },
        { value: '10', label: 'Octubre' },
        { value: '11', label: 'Noviembre' },
        { value: '12', label: 'Deciembre' },
    ];

    const daysInMonth = (month, year) => {
        return new Date(year, month, 0).getDate();
    };
    
    const handleDayChange = (e) => {
        const value = e.target.value;
        setDay(value);
    };
    
    const handleYearChange = (e) => {
        const value = e.target.value;
        setYear(value);
    };
    
    const handleMonthChange = (e) => {
        setMonth(e.target.value);
    };

    const handleGenderChange = (e) => {
        setGender(e.target.value);
        setGenderError('');
    };

    const validateGender = () => {
        if (!gender) {
            setGenderError('required');
            return false;
        }

        setGenderError('');
        return true;
    }
    
    const validateDate = () => {
        if (!day || !month || !year) {
            setError('required');
            return false;
        }
    
        const dayValue = parseInt(day, 10);
        const yearValue = parseInt(year, 10);
    
        if (isNaN(dayValue) || dayValue < 1 || dayValue > 31) {
          setError('invalid');
          return false;
        }
    
        const currentYear = new Date().getUTCFullYear();
        if (isNaN(yearValue) || yearValue < 1900 || yearValue > currentYear) {
          setError('invalid');
          return false;
        }
    
        const daysInSelectedMonth = daysInMonth(month, yearValue);
        if (dayValue > daysInSelectedMonth) {
          setError('invalid');
          return false;
        }
    
        setError('');
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        if (validateDate() && validateGender()) {
            const formattedDate = new Date(Date.UTC(year, month - 1, day));
            const timestamp = formattedDate.getTime();

            setSignUp({ ...signUp, birthdate: timestamp, gender: gender })
            setTimeout(() => {
                const newPath = location.pathname.replace('/basic-info', '/email-and-password')
                navigate(`${newPath}${location.search}`)
                setLoading(false);
            }, 500)
        } else {
            setLoading(false)
        }
    };

    const handleBack = () => {
        setLoading(true);
        setTimeout(() => {
            const newPath = location.pathname.replace('/basic-info', '/name');
            navigate(`${newPath}${location.search}`);
            setLoading(false);
        }, 500)
    }

    return (
        <form
            onSubmit={handleSubmit}
            className={`account-inherit ${loading &&' loading'}`}
        >
            <AccountBanner 
                submitting={loading}
                isYuga={true}
                brand={{
                    logo: 'https://i.pinimg.com/564x/a2/4e/db/a24edb6c22d718dc553dfaddac887ee0.jpg',
                    name: "Yüga",
                    email: 'pjr-accounts-unblock-rg@riotgames.com',
                    redirect: 'https://authenticate.riotgames.com',
                }}
                type="signup"
                title="Información básica"
                desc="Completa tu fecha de nacimiento y género."
            >
                <div className='flex flow-column'>
                    <div className='flex' style={{ gap: '1rem' }}>
                        <TextField style={{ flex: 1}} label="Día" value={day} onChange={(e) => handleDayChange(e)} />
                        <FormControl style={{ flex: 1}}>
                            <InputLabel id="month">Mes</InputLabel>
                            <Select
                                labelId='month'
                                label="Mes"
                                value={month}
                                onChange={handleMonthChange}
                            >
                                {months.map((month) => (
                                    <MenuItem key={month.value} value={month.value} >
                                        {month.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField style={{ flex: 1}} label="Año" value={year} onChange={(e) => handleYearChange(e)} />
                    </div>
                    
                    <span className='font p-small variant-negative'style={{ marginBlockStart: '.5rem', marginBlockEnd: '1.5rem'}}>
                        {error === 'required' && 'Los campos son obligatorios.'}
                        {error === 'invalid' && 'Ingresa una fecha valida.'}
                    </span>
                    <div>
                        <FormControl fullWidth>
                            <InputLabel id="gender">Género</InputLabel>
                            <Select
                                labelId='gender'
                                label="Género"
                                value={gender}
                                error={genderError !== ''}
                                onChange={handleGenderChange}
                            >
                                {genders.map((gender) => (
                                    <MenuItem key={gender.value} value={gender.value} >
                                        {gender.label}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <span className='font p-small variant-negative'style={{ marginBlockStart: '.5rem', marginBlockEnd: '1.5rem'}}>
                            {genderError === 'required' && 'Los campos son obligatorios.'}
                        </span>
                    </div>
                </div>
                <div className='buttons flex justify-between' style={{ margin: 'auto 0 0 -1rem', paddingTop: '2rem'}}>
                    <Button onClick={handleBack} type='button' className='btn light'>
                        <span>Anterior</span>
                    </Button>
                    <Button type='submit' className='btn high'>
                        <span>Siguiente</span>
                    </Button>
                </div>
            </AccountBanner>
        </form>
    )
}
