import { CloudSync, Share, Sync } from '@mui/icons-material'
import { Button, IconButton, TextField, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Modal from './Modal/Modal'
import { doc, getDoc } from 'firebase/firestore';
import { activeSessionsFirestore } from '../services/firebase/firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import { decrypt } from '../page/version/1/functions';
import Cookies from "js-cookie";

export default function SyncButton() {
    const location = useLocation();
    const navigate = useNavigate();
    const [ syncModal, setSyncModal ] = useState(false);

    const [ shareSync, setShareSync ] = useState(false);

    const [ syncSession, setSyncSession ] = useState(false);
    const [ sessionId, setSessionId ] = useState('');

    const [ error, setError ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const handleOpen = () => {
        setSyncModal(true);
    }

    const handleClose = () => {
        setSyncModal(false);
        setLoading(false);
    }

    const handleShareSyncOpen = () => {
        setShareSync(true);
    }

    const handleShareSyncClose = () => {
        setShareSync(false);
        setLoading(false);
    }

    const handleShare = () => {
        setLoading(true);

        setTimeout(() => {
            handleShareSyncOpen();
            setTimeout(() => {
                handleClose();
            }, 50)
        }, 500)
    }

    const handleOpenSycnSession = () => {
        setLoading(false);
        setSyncSession(true);
    }

    const handleCloseSycnSession = () => {
        setSyncSession(false);
    }

    const handleBack = () => {
        handleOpen();
        setTimeout(() => {
            setSessionId('');
            handleCloseSycnSession();
            handleShareSyncClose();
        }, 50)
        setSyncModal(true);
    }

    const handleSync = () => {
        setLoading(true);

        setTimeout(() => {
            handleOpenSycnSession();
            setTimeout(() => {
                handleClose();
            }, 50)
        }, 500)
    }

    const handleSyncSession = async (e) => {
        e.preventDefault();
        setLoading(true);

        const decrypSessionId = await decrypt(sessionId)
        
        const sessionRef = doc(activeSessionsFirestore, `active_sessions/${decrypSessionId}`)
        const sessionSnap = await getDoc(sessionRef);

        if (sessionSnap.exists()) {
            localStorage.setItem('sessionId', sessionId);
            Cookies.set('sessionId', sessionId, { domain: '.yugacore.com' });

            if (location.pathname.split('/')[2] === 'account-chooser') {
                window.location.reload()
            }

            if (location.pathname.split('/')[2] === 'signin') {
                const newPath = location.search;
                navigate(`/v1/account-chooser/identifier${newPath}`);
                setLoading(false);
            }
        } else {
            setError(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        setError('');
    }, [sessionId])

    return (
        <>
            <Tooltip title="Sincronizar sesión">
                <IconButton onClick={handleOpen}>
                    <CloudSync />
                </IconButton>
            </Tooltip>
            <Modal 
                title="Sincronizar cuentas del navegador"
                open={syncModal}
                loading={loading}
                close={handleClose}
            >
                <div className='btn card'>
                    <Button className='card-link' onClick={handleSync}>
                        <div className="card-link_layout">
                            <div>
                                <div className='icon'>
                                <Sync /> 
                                </div>
                                <div className="body">
                                    <div className="name">Sincronizar sesión</div>
                                </div>
                            </div>
                        </div>
                    </Button>
                </div>
                
                {localStorage.getItem('sessionId') &&
                    <div className='btn card'>
                        <Button className='card-link' onClick={handleShare}>
                            <div className="card-link_layout">
                                <div>
                                    <div className='icon'>
                                    <Share /> 
                                    </div>
                                    <div className="body">
                                        <div className="name">Compartir sesión</div>
                                    </div>
                                </div>
                            </div>
                        </Button>
                    </div>
                }
            </Modal>
            <Modal
                title={"Sincronizar sesión"}
                open={syncSession}
                close={handleCloseSycnSession}
                loading={loading}
            >
                <form
                    onSubmit={handleSyncSession}
                >
                    <div style={{ marginBottom: '1.5rem'}}>
                        <TextField 
                            className='full-width'
                            label="Sesión ID"
                            required
                            value={sessionId}
                            error={error ? true : false}
                            helperText={error && 'Ingresa una ID valida.'}
                            onChange={(e) => setSessionId(e.target.value)}
                        />
                    </div>
                    <div className="flex flow-row justify-between" style={{ marginLeft: '-1rem'}}>
                        <Button type='button' onClick={handleBack} className='btn light'>
                            Volver
                        </Button>
                        <Button type='submit' className='btn high'>
                            Sincronizar
                        </Button>
                    </div>
                </form>
            </Modal>
            <Modal
                title={"Compartir sesión"}
                open={shareSync}
                close={handleShareSyncClose}
                loading={loading}
            >
                <div style={{ marginBottom: '1.5rem'}}>
                    <TextField 
                        className='full-width'
                        label="Sesión ID"
                        value={localStorage.getItem('sessionId')}
                        required
                        helperText='Copia este ID para sincronizar esta sesión en otro navegador.'
                    />
                    <div className='flex flow-column' style={{ marginTop: '1rem', gap: '.25rem'}}>
                        <span className='font p-small'></span>
                        <span className='font p-small'>Esta información es para uso personal. Ningún trabajador de Yüga te pedira la ID de tu sesión, no la compartas con nadie.</span>
                    </div>
                </div>
                <div className="flex flow-row justify-between" style={{ marginLeft: '-1rem'}}>
                    <Button type='button' onClick={handleBack} className='btn light'>
                        Volver
                    </Button>
                </div>
            </Modal>
        </>
    )
}
