import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

export default function SignUpHandler() {

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          const message = 'Are you sure you want to leave? Your changes may not be saved.';
          event.preventDefault();
          event.returnValue = message;
          return message;
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        const handleUnload = (event) => {
          event.preventDefault();
          event.returnValue = ''; // Necesario para algunos navegadores
        };
    
        window.addEventListener('unload', handleUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
          window.removeEventListener('unload', handleUnload);
        };
      }, []);

    return <Outlet />
}
