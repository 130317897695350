import React, { useContext, useEffect, useState } from 'react'
import AccountBanner from '../../../../components/AccountBanner'
import { Button, Checkbox, FormControlLabel, IconButton, TextField } from '@mui/material'
import { Circle, Visibility, VisibilityOff } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../context/AuthContext';
import Modal from '../../../../components/Modal/Modal';

const ListItem = ({ title, link, exists }) => {

    if (!exists) return;

    return (
        <li>
            {link && 
                <Button role='a' onClick={link} className='btn text-lite'>
                    <Circle className='list-image' />
                    <span>{title}</span>
                </Button>
            }
        </li>
    )
}

export default function EmailAndPassword() {
    const navigate = useNavigate();
    const location = useLocation();

    const { signUp, setSignUp, registerYuga } = useContext(AuthContext);
 
    const [ email, setEmail ] = useState(signUp.email);
    const [ password, setPassword ] = useState(signUp.password);
    const [ confirmPassword, setConfirmPassword ] = useState(signUp.confirmPassword);
    const [ saveAccount, setSaveAccount ] = useState(signUp.saveAccount);

    const [ modal, setModal ] = useState(false);

    const [ loading, setLoading ] = useState(false);
    const [ disabled, setDisabled ] = useState(true);

    const [ visibility, setVisibility ] = useState({
        password: 'password',
        confirmPassword: 'password'
    })

    const [ errors, setErrors ] = useState({
        invalidEmail: false,
        passwordTooLong: false,
        passwordDontMatch: false,
        passwordInsecure: true,
        passwordTooLess: true
    })

    const handleSaveAccount = (event) => {
        setSaveAccount(event.target.checked);
    };

    const handleOpenModal = () => {
        setModal(true);
    }

    const handleCloseModal = () => {
        setModal(false);
        setLoading(false);
    }

    const verifyData = () => {
        if (signUp.name && signUp.birthdate && signUp.gender) return true;

        return false;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        if (verifyData()) {
            setSignUp({ ...signUp, email: email, password, confirmPassword: confirmPassword, saveAccount: saveAccount });

            setTimeout(() => {
                registerYuga(email, password, saveAccount);
            }, 500);
        } else {
            setSignUp({ ...signUp, email: email, password, confirmPassword: confirmPassword, saveAccount: saveAccount });
            handleOpenModal();
        }
    }

    const handleBack = (value, isLink) => {
        setLoading(true);
        setTimeout(() => {
            const newPath = location.pathname.replace('/email-and-password', value);

            navigate(`${newPath}${location.search}`);
            setLoading(false);
        }, 500)
    }

    useEffect(() => {
        const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (email.length > 0) {
            if (!email.match(validRegex)) {
                setErrors({...errors, invalidEmail: true})
            } else {
                setErrors({...errors, invalidEmail: false})
            }
        } else {
            setErrors({...errors, invalidEmail: false})
        }
    }, [email])

    useEffect(() => {
        if (confirmPassword.length > 0) {
            if (password !== confirmPassword) {
                setErrors({...errors, passwordDontMatch: true})
            } else {
                setErrors({...errors, passwordDontMatch: false})
            }
        } else {
            setErrors({...errors, passwordDontMatch: false})
        }
    }, [confirmPassword])

    useEffect(() => {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@!#$%&?._-]/

        if (password.length > 0) {
            setErrors({...errors, passwordTooLong: password.length > 30, passwordTooLess: password.length < 8, passwordInsecure: !password.match(passwordRegex)})
        } else {
            setErrors({
                passwordDontMatch: false,
                passwordInsecure: true,
                passwordTooLess: true,
                passwordTooLong: false
            })
        }     
    }, [password])

    useEffect(() => {
        if ((email.length > 0) && (password.length > 0) && (confirmPassword.length > 0)) {
            if ((!errors.invalidEmail) && (!errors.passwordDontMatch) && (!errors.passwordInsecure) && (!errors.passwordTooLess) && (!errors.passwordTooLong)) {
                setDisabled(false)
            } else {
                setDisabled(true)
            }
        } else {
            setDisabled(true)
        }
    }, [email, password, confirmPassword, errors])

    return (
        <>
            <form
                className={`account-inherit ${loading && 'loading'}`}
                onSubmit={handleSubmit}
            >
                <AccountBanner 
                    submitting={loading}
                    isYuga={true}
                    brand={{
                        logo: 'https://i.pinimg.com/564x/a2/4e/db/a24edb6c22d718dc553dfaddac887ee0.jpg',
                        name: "Yüga",
                        email: 'pjr-accounts-unblock-rg@riotgames.com',
                        redirect: 'https://authenticate.riotgames.com',
                    }}
                    type="signup"
                    title="Cómo acceder"
                    desc="Usa tu dirección de correo electrónico para acceder a tu cuenta de Yüga."
                >   
                    <div
                        className='flex flow-column'
                        style={{ gap: '1rem'}}
                    >
                        <TextField 
                            className='full-width'
                            label="Correo electrónico"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            autoComplete='new-email'
                            helperText={errors.invalidEmail && 'Correo no valido.'}
                            error={errors.invalidEmail}
                        />
                        <TextField 
                            type={visibility.password}
                            className='full-width'
                            label="Contraseña"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                            autoComplete='new-password'
                            error={password.length > 0 ? (errors.passwordInsecure) || (errors.passwordTooLess) || (password.length > 0 && errors.passwordTooLong) : false}
                            InputProps={{
                                endAdornment: 
                                    <IconButton 
                                        style={{ marginRight: '-.5rem'}}
                                        onClick={() => setVisibility({...visibility, password: visibility.password === 'password' ? 'text' : 'password'})}>
                                        {visibility.password === 'password' ? 
                                            <VisibilityOff />
                                        :
                                            <Visibility />
                                        }
                                        
                                    </IconButton>
                            }}
                        />
                        <div style={{ display: 'flex', flexFlow: 'column', marginBottom: '1rem'}}>
                            <span className={`input-msg ${errors.passwordTooLess ? 'error' : 'complete'}`}>Minimo 7 carácteres</span>
                            <span className={`input-msg ${password.length > 0 && errors.passwordTooLong ? 'error' : 'complete'}`}>Máximo 30 carácteres</span>
                            <span className={`input-msg ${errors.passwordInsecure ? 'error' : 'complete'}`}>Debe tener al menos una letra, un número y un carácter especial (@, !, #, $, %, &, ?)</span>
                        </div>
                        <TextField 
                            type={visibility.confirmPassword}
                            className='full-width'
                            label="Confirmar Contraseña"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            error={errors.passwordDontMatch}
                            helperText={errors.passwordDontMatch ? 'Las contraseñas no coinciden.' : ''}
                            InputProps={{
                                endAdornment: 
                                    <IconButton 
                                        onClick={() => setVisibility({...visibility, confirmPassword: visibility.confirmPassword === 'password' ? 'text' : 'password'})}>
                                        {visibility.confirmPassword === 'password' ? 
                                            <VisibilityOff />
                                        :
                                            <Visibility />
                                        }
                                        
                                    </IconButton>
                            }}
                        />
                        <FormControlLabel 
                            checked={saveAccount} 
                            onChange={handleSaveAccount} 
                            style={{ 
                                margin: '0', 
                                display: 'flex',
                                alignItems: 'center'
                            }} 
                            control={
                                <Checkbox 
                                    sx={{ 
                                        color: '#5a5a5a', 
                                        marginLeft: '-9px',
                                        marginRight: '-4.5px',
                                        marginTop: '-2px',
                                        '&.Mui-checked': { 
                                            color: '#000'
                                        } 
                                    }} 
                                />} 
                            label="Guardar cuenta en el navegador." 
                        />
                        <div className="account-chooser_footer" style={{ marginTop: '0'}}>
                            <div>
                                {`Al continuar, aceptas estar de acuerdo con nuestra `}
                                <a href="https://policies.yugacore.com/privacy" target='_blank'rel='noreferrer noopener' className='btn text-lite'>política de privacidad</a> y <a href="https://policies.yugacore.com/terms" target='_blank'rel='noreferrer noopener' className='btn text-lite'>condiciones del servicio</a>. 
                            </div>
                        </div>
                    </div>
                    <div className='buttons flex justify-between' style={{ margin: 'auto 0 0 -1rem', paddingTop: '2rem'}}>
                        <Button onClick={() => handleBack('/basic-info')} type='button' className='btn light'>
                            <span>Anterior</span>
                        </Button>
                        <Button disabled={disabled} type='submit' className='btn high'>
                            <span>Crear cuenta</span>
                        </Button>
                    </div>
                </AccountBanner>
            </form>
            <Modal open={modal} title="Falta información" close={handleCloseModal}>
                <div>
                    <span>Para poder crear tu cuenta, necesitamos la siguiente información:</span>
                    <ul className='list style-circle' style={{ marginTop: '1rem', lineHeight: '1.265rem' }}>
                        <ListItem 
                            link={() => {
                                setModal(false);
                                setLoading(true);
                                setTimeout(() => {
                                    handleBack('/name', true);
                                }, 200)
                            }} 
                            exists={!signUp.name} 
                            title="Nombre" 
                        />
                        <ListItem 
                            link={() => {
                                setModal(false);
                                setLoading(true);
                                setTimeout(() => {
                                    handleBack('/name', true);
                                }, 200)
                            }} 
                            exists={!signUp.lastName} 
                            title="Apellido (opcional)" 
                        />
                        <ListItem 
                            link={() => {
                                setModal(false);
                                setLoading(true);
                                setTimeout(() => {
                                    handleBack('/basic-info', true);
                                })
                            }} 
                            exists={!signUp.birthdate} 
                            title="Fecha de nacimiento" 
                        />
                        <ListItem 
                            link={() => {
                                setModal(false);
                                setLoading(true);
                                setTimeout(() => {
                                    handleBack('/basic-info', true);
                                })
                            }} 
                            exists={!signUp.gender} 
                            title="Género" 
                        />
                    </ul>
                </div>
                <div className='flex flow-row justify-end' style={{ marginTop: '2rem'}}>
                    <Button className='btn light' onClick={handleCloseModal}>
                        Entendido
                    </Button>
                </div>
            </Modal>
        </>
    )
}
