import { createContext, useContext } from "react";

const APIContext = createContext();

export const useApi = () => useContext(APIContext);

const fakeApi = {
    fetchTest: async () => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        return { data: 'Resultado simulado de la API'}
    },
};

export const APIProvider = ({ children }) => (
    <APIContext.Provider value={fakeApi}>
        {children}
    </APIContext.Provider>
)