import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import { doc, getDoc } from 'firebase/firestore';
import { activeSessionsFirestore, usersFirestore } from '../../../services/firebase/firebase';
import { Avatar, Button } from '@mui/material';
import { AccountCircleOutlined } from '@mui/icons-material';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountBanner from '../../../components/AccountBanner';
import AccountFooter from '../../../components/AccountFooter';
import AccountLoading from '../../../components/AccountLoading';
import getAppId from './functions/getAppId';
import Cookies from 'js-cookie';
import { decrypt } from './functions';
import './Identifier.scss';

export default function AccountChooser({ type }) {
    const location = useLocation();
    const navigate = useNavigate();
    const { loginYuga, thirdPartyLogin, syncWithYuga } = useContext(AuthContext);
    
    const [ data, setData ] = useState({});
    const [ owner, setOwner ] = useState('');

    const [ signin, setSignin ] = useState(false);
    const [ accounts, setAccounts ] = useState(undefined);
    
    const [ secondView, setSecondView ] = useState({
        visible: false,
        uid: ''
    });
    
    const [ loading, setLoading ] = useState(true);
   
    const [ isYuga, setIsYuga ] = useState(false);

    async function getUserDocuments(userIds) {
        const userDocs = await Promise.all(userIds.map(async (userId) => {
            const decryptUid = await decrypt(userId);

            const userDocRef = doc(usersFirestore, `${decryptUid}/public`);
            const userDoc = await getDoc(userDocRef);

            if (userDoc.exists()) {
                return { id: decryptUid, ...userDoc.data().data[0] };
            } else {
                console.log(`No such document for user ID: ${decryptUid}`)
                return null;
            }
        }));

        return userDocs.filter(userDoc => userDoc !== null);
    }

    async function getAccounts() {
        const session = localStorage.getItem('sessionId');
        console.log(1)

        if (session) {
            console.log(2)
            const sessionId = await decrypt(session);
            const listRef = doc(activeSessionsFirestore, `active_sessions/${sessionId}/accounts/list`);
            const listSnap = await getDoc(listRef);

            if (listSnap.exists()) {
                const docItems = listSnap.data();
                const data = docItems.data;
                setOwner(docItems.name);

                getUserDocuments(data)
                    .then(userDocs => {
                        setAccounts(userDocs);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error fetching user documents: ', error)
                    })
            } else {
                const newPath = location.pathname.replace(`/account-chooser/${type}`, '/signin');
                navigate(`${newPath}${location.search}`);
                localStorage.removeItem('sessionId');
            }
        } else {
            console.log(3)
            const newPath = location.pathname.replace(`/account-chooser/${type}`, '/signin');

            navigate(`${newPath}${location.search}&authType=${type}`);
        }
    }

    const handleLogin = (uid) => {
        if (type === "identifier") {
            setSignin(true);
            Cookies.set('sessionId', localStorage.getItem('sessionId'), { domain: '.yugacore.com'});
            if (isYuga) {
                loginYuga(uid)
            } else {
                thirdPartyLogin(uid);
            }
        }

        if (type === "sync") {
            setSecondView({
                visible: true,
                uid: uid
            });
        }
    }

    const handleSync = () => {
        setSignin(true);
        setTimeout(() => {
            syncWithYuga(secondView.uid);
        }, 1000);
    }

    const handleUseAnotherAccount = () => {
        const newPath = location.search;
        navigate(`/v1/signin${newPath}&authType=${type}`)
    }

    useEffect(() => {
        if (accounts === undefined) {
            getAccounts();
        }
    }, [])

    useEffect(() => {
        getAppId(setData, setLoading, setIsYuga);
    }, [])

    if (loading) {
        return <AccountLoading />
    }

    return (
        <div
            className={`account-inherit ${signin && 'loading'}`}
        >
            <AccountBanner 
                submitting={signin}
                isYuga={isYuga}
                brand={data}
                sessionOwner={owner}
            >
                {accounts ?
                    <>
                        {!secondView.visible ? 
                            <div>
                                {accounts.map((account) => (
                                    <div key={account.email} className='account_card'>
                                        <Button onClick={() => handleLogin(account.id)} className='account_card-link'>
                                            <div className='account_card-link_layout'>
                                                <div>
                                                    <div className='avatar'>
                                                        <Avatar 
                                                            alt={account.name}
                                                            src={account.photoURL}
                                                        />
                                                    </div>
                                                    <div className='body'>
                                                        <div className='name'>{account.name}</div>
                                                        <div className='email'>{account.email}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Button>
                                    </div>
                                ))}
                                <div className="account_card">
                                    <Button onClick={handleUseAnotherAccount} className='account_card-link'>
                                        <div className='account_card-link_layout'>
                                            <div>
                                                <div className='icon'>
                                                    <AccountCircleOutlined />
                                                </div>
                                                <div className='body'>
                                                    <div className='name'>Usar otra cuenta</div>
                                                </div>
                                            </div>
                                        </div>
                                    </Button>
                                </div>
                                <AccountFooter 
                                    brand={data}
                                />
                            </div>  
                            :
                            <div>
                                <div style={{ marginBottom: '1.5rem'}}>
                                    <span className="font p-small">Estas a punto de vincular tu cuenta de <span className="font weight-bold">Yüga</span> con <Button className="btn text-lite">{data.name}</Button> </span>
                                    <span className="font p-small">Al aceptar, <span className="font weight-bold">Yüga</span> compartirá tu nombre, tu foto de perfil, tu fondo de perfil y tu correo electrónico con <span className="font weight-bold">{data.name}</span>.</span>
                                    <span className='font p-small'>Consulta la Política de Privacidad y las Condiciones del Servicio de <span className="font weight-bold">{data.name}</span>.</span>
                                </div>
                                <div className="flex justify-between">
                                    <Button 
                                        className="btn medium" 
                                        style={{ width: '44%'}}
                                        onClick={() => {
                                            setTimeout(() => {
                                                setSecondView({
                                                    visible: false,
                                                    uid: ''
                                                })
                                            }, 200);
                                        }}
                                    >
                                        Volver
                                    </Button>
                                    <Button onClick={handleSync} className="btn high" style={{ width: '44%'}}>
                                        Confirmar
                                    </Button>
                                </div>
                            </div>
                        }
                    </>
                :
                    <AccountLoading noMinHeight />
                }
            </AccountBanner>
        </div>
    )
}
