import { Avatar, Button, LinearProgress, Skeleton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import YugaLogo from '../assets/Logo'
import Modal from './Modal/Modal'
import { LockRounded } from '@mui/icons-material';

export default function AccountBanner({ 
    submitting, 
    isYuga,
    brand,
    type,
    desc,
    title,
    children,
    sessionOwner
}) {
    const [ open, setOpen ] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    useEffect(() => {
        if (submitting) {
            window.scrollTo(0 ,0)
        }
    }, [submitting])
    
    return (
        <>
            <div className={`account_banner ${isYuga && 'isYuga'}`}>
                {submitting && <LinearProgress className='progress linear' />}
                <div className="brand">
                    {isYuga ?
                        <div className="logo">
                            <YugaLogo />
                        </div>
                    :
                        <>
                            <div className='logo'>
                                <Avatar 
                                    sx={{ width: '4rem', height: '4rem', borderRadius: 1}}
                                    src={brand.logo}
                                    alt={brand.name}
                                />
                                <div>
                                    <h2>{brand.name}</h2>
                                </div>
                            </div>
                            <div className='brand_layout'>
                                <div className="logo">
                                    <YugaLogo />
                                </div>
                                <div className="text">Acceder con Yüga</div>
                            </div>
                        </>
                    }
                </div>
                <div className='body'>
                    <div>
                        {type === 'signup' ?
                            <span>
                                {desc}
                            </span>
                            :
                            <span>
                                {(type === "signin") && 'Ir a '}
                                {(!type || type === "account-chooser") && 'para ir a '} 
                                <Button onClick={handleOpen} className='btn text-lite'>{brand.name}</Button>
                                <Modal
                                    title={"Información para el programador"}
                                    open={open}
                                    close={handleClose}
                                >
                                    <div style={{ display: 'flex', flexFlow: 'column', gap: '1rem'}}>
                                        <div className='font p-small'>
                                            <span>Correo electrónico: </span>
                                            <a href={`mailto:${brand.email}`} className='btn text-lite' >{brand.email}</a>
                                        </div>
                                        <div className='font p-small'>
                                            Si eliges una cuenta, se te redireccionará a: {brand.redirect}
                                        </div>
                                    </div>
                                    <div className='flex flow-row justify-end' style={{ marginTop: '2rem'}}>
                                        <Button className='btn light' onClick={handleClose}>
                                            Entendido
                                        </Button>
                                    </div>
                                </Modal>
                            </span>
                        }
                    </div>
                </div>
            </div>
            <div className={`${isYuga && 'isYuga'}`}>
                <h1 className={`${(!type || type === "account-chooser") && 'account-choose'}`}>
                    {(type === "signin") ? (title) ? title : 'Acceder' : ''}
                    {(type === "signup") ? (title) ? title : 'Crea una cuenta de Yüga' : ''}
                    {(!type || type === "account-chooser") && 'Elegir una cuenta'} 
                </h1>
                {(!type || type === "account-chooser") && 
                    <div className='session-owner'>
                        <LockRounded />
                        {sessionOwner ?
                        `Sesión de ${sessionOwner}`    
                        :
                        <Skeleton height={33.33} variant='text' />
                        }
                    </div>    
                }
                {children}
            </div>
        </>
    )
}
