import React, { useContext, useEffect, useState } from 'react'
import AccountBanner from '../../../../components/AccountBanner'
import { Button, CircularProgress, TextField } from '@mui/material'
import AccountFooter from '../../../../components/AccountFooter'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getAppData } from '../functions/getAppData'
import { AuthContext } from '../../../../context/AuthContext'
import AccountLoading from '../../../../components/AccountLoading'
import getAppId from '../functions/getAppId'

export default function Email() {
    const { signIn, setSignIn } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();

    const [ email, setEmail ] = useState(signIn.email);

    const [ data, setData ] = useState({});

    const [ submitting, setSubmitting ] = useState(false);
    const [ loading, setLoading ] = useState(true);
    
    const [ isYuga, setIsYuga ] = useState(false);

    const [ errors, setErrors ] = useState({
        invalidEmail: false,
    });

    const handleEmail = (e) => {
        e.preventDefault();
        setSubmitting(true);
        setSignIn({ ...signIn, email: email})
        setTimeout(() => {
            const newPath = location.pathname.replace('/signin', '/signin/auth');
            navigate(`${newPath}${location.search}`);
            setLoading(false);
        }, 500)
    }

    useEffect(() => {
        getAppId(setData, setLoading, setIsYuga);
    }, [])

    useEffect(() => {
        const validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (email.length > 0) {
            if (!email.match(validRegex)) {
                setErrors({...errors, invalidEmail: true})
            } else {
                setErrors({...errors, invalidEmail: false})
            }
        } else {
            setErrors({...errors, invalidEmail: false})
        }
    }, [email])

    if (loading) {
        return <AccountLoading />
    }

    return (
        <form 
            className={`account-inherit ${submitting && 'loading'}`}
            onSubmit={handleEmail}
        >
            <AccountBanner 
                submitting={submitting}
                isYuga={isYuga}
                brand={data}
                type="signin"
            > 
                <div>
                    <TextField 
                        className='full-width'
                        label="Correo electrónico"
                        style={{ marginBottom: '.5rem'}}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        autoComplete='new-email'
                        helperText={errors.invalidEmail && 'Correo no valido.'}
                        error={errors.invalidEmail}
                    />
                    <Button className='btn text-lite'>
                        <span>¿Olvidaste el correo electrónico?</span>
                    </Button>
                    <AccountFooter 
                        brand={data}
                    />
                </div>
                <div className='buttons flex justify-between' style={{ margin: 'auto 0 0 -1rem', paddingTop: '2rem'}}>
                    <Button type='button' to={`/v1/signup/name${location.search}`} component={Link} className='btn light'>
                        <span>Crear cuenta</span>
                    </Button>
                    <Button type='submit' className='btn high'>
                        <span>Siguiente</span>
                    </Button>
                </div>
            </AccountBanner>
        </form>
    )
}
