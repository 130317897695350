import { createBrowserRouter } from "react-router-dom";
import Home from "../page";
import Layout from "../page/Layout";
import VersionLayout from "../page/version/1/Layout";
import { Email, Password } from "../page/version/1/SignIn";
import AccountChooser from "../page/version/1/AccountChooser";
import { BasicInfoSignUp, EmailAndPasswordSignUp, NameSignUp, RedirectSignUp } from "../page/version/1/SignUp";
import SignUpHandler from "../page/version/1/SignUp/SignUpHandler";
import Test from "../page/api/test";
import TestFetcher from "../page/api/fetch/test";

export const YugaRouter = createBrowserRouter([
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <Home />
            },
            {
                path: 'v1',
                element: <VersionLayout />,
                children: [
                    {
                        path: 'account-chooser',
                        children: [
                            {
                                path: 'identifier',
                                element: <AccountChooser type="identifier" />
                            },
                            {
                                path: "sync",
                                element: <AccountChooser type="sync" />
                            }
                        ]
                    },
                    {
                        path: 'signin',
                        children: [
                            {
                                index: true,
                                element: <Email />
                            },
                            {
                                path: "auth",
                                element: <Password />
                            }
                        ]
                    },
                    {
                        path: "signup",
                        element: <SignUpHandler />,
                        children: [
                            {
                                index: true,
                                element: <RedirectSignUp />,
                            },
                            {
                                path: "name",
                                element: <NameSignUp />
                            },
                            {
                                path: 'basic-info',
                                element: <BasicInfoSignUp />
                            },
                            {
                                path: "email-and-password",
                                element: <EmailAndPasswordSignUp />
                            }
                        ]
                    }
                ]
            },/* 
            {
                path: 'api',
                children: [
                    {
                        path: "test",
                        element: <Test />
                    },
                    {
                        path: "fetch",
                        children: [{
                            path: "test",
                            element: <TestFetcher />
                        }]
                    }
                ]
            } */
        ]
    }
])