import React from 'react'
import { getAppData } from './getAppData'

export default function getAppId(setData, setLoading, setIsYuga) {
    const query = new URLSearchParams(window.location.search);
    const origin = query.get('origin');

    if (origin && origin === 'zWRPsx1OqWLfBmlKYJLG') {
        getAppData(origin)
        .then((appData) => {
            setIsYuga(true);
            setData(appData);
            setLoading(false);
        })
        .catch((error) => {
            setIsYuga(false);
            setLoading(false);
            console.error(error)
        })
    } else {
        if (query.get('appId')) {
            setIsYuga(false);
            getAppData(query.get('appId'))
            .then((appData) => {
                setData(appData);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error)
            })
        } else {
            setIsYuga(false);
            setLoading(false);
            setData({
                apiKey: 'test',
                email: "test@gmail.com",
                logo: "https://i.pinimg.com/736x/4e/e6/86/4ee686cbb474126274ab38edec992fb8.jpg",
                name: "Test",
                policies: {
                    privacy: "https://policies.test.com/privacy",
                    terms: "https://policies.test.com/terms"
                },
                redirect: "myaccounts.test.com"
            });
            console.log('otra app')
        }
    }

}
