import React from 'react'
import { Outlet } from 'react-router-dom'
import WarningMessage from '../components/WarningMessage'
import { AuthProvider } from '../context/AuthContext'

export default function Layout() {
  return (
    <AuthProvider>
        <WarningMessage />
        <Outlet />
    </AuthProvider>
  )
}
