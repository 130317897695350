import { createUserWithEmailAndPassword, onAuthStateChanged, signInWithCustomToken, updateProfile } from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import { activeSessionsFirestore, auth, usersFirestore, usersStorage } from "../services/firebase/firebase";
import axios from "axios";
import Cookies from "js-cookie";
import { arrayRemove, arrayUnion, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import userDefaultPfp from '../assets/default-user-logo.png';
import { useNavigate } from "react-router-dom";
import { decrypt, encrypt } from "../page/version/1/functions";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();

    const [ user, setUser ] = useState(undefined);
    const [ signUp, setSignUp ] = useState({
        name: '',
        lastName: '',
        birthdate: '',
        gender: '',
        email: '',
        password: '',
        confirmPassword: '',
        saveAccount: false
    })
    const [ signIn, setSignIn ] = useState({
        email: '',
        password: ''
    })

    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        })
    }, [])

    const handleLoginYuga = async (uid) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URI}/verifyUid`, { 
                uid: uid 
            });
            const customToken = response.data.customToken;
    
            const user = await signInWithCustomToken(auth, customToken);
            const idToken = await user.user.getIdToken();
            Cookies.set('authToken', idToken, { domain: '.yugacore.com' });
            navigate('/');
        } catch (error) {
            console.log(error)
        } 
    }

    const handleSyncWithYuga = async (uid) => {
        const query = new URLSearchParams(window.location.search);
        const appId = query.get('appId');

        console.log(1)

        try {
            console.log(2)
            const response = await axios.post(`${process.env.REACT_APP_API_URI}/verifyUid`, { 
                uid: uid 
            });

            const customToken = response.data.customToken;

            const user = await signInWithCustomToken(auth, customToken);

            const userConnections = doc(usersFirestore, `${user.user.uid}/data-and-privacy/connections/${appId}`);
            const userConnectionsSnap = await getDoc(userConnections);

            if (userConnectionsSnap.exists()) {
                console.log(3)
                const result = {
                    success: false,
                    message: 'This user already sync.'
                }

                window.opener.postMessage({ result: result }, '*');
                return;
            }

            const userRef = doc(usersFirestore, `${user.user.uid}/personal-info`);
            const userSnap = await getDoc(userRef);

            if (userSnap.exists()) {
                console.log(4)
                const userData = userSnap.data().data[0];
                const result = {
                    success: true,
                    user: {
                        name: userData.basic.publicName,
                        email: userData.contact.primaryEmail,
                        photoURL: userData.basic.photoURL,
                        bannerURL: userData.basic.bannerURL
                    }
                }
    
                window.opener.postMessage({ result: result, uid: user.user.uid }, '*');
            } else {
                console.log(5)
                const result = {
                    success: true,
                    user: {
                        name: user.user.displayName,
                        email: user.user.email,
                        photoURL: user.user.photoURL,
                        bannerURL: null
                    }
                }
    
                window.opener.postMessage({ result: result, uid: user.user.uid }, '*');
            }

        } catch (error) {
            console.log(error)
        }
    }

    const handleThirdPartyLogin = async (uid) => {
        try {
            const query = new URLSearchParams(window.location.search);
            const aid = query.get('appId');

            const userFinder = doc(usersFirestore, `${uid}/data-and-privacy/connections/${aid}`);
            const userFinderSnap = await getDoc(userFinder);


            if (userFinderSnap.exists()) {    
                window.opener.postMessage({ token: userFinderSnap.data().uid }, '*');
            } else {
                alert('Login failed')
            }

        
        } catch (error) {
            console.log(error);
        }
    }

    const handleCookies = async (userCredential) => {
        try {
            const idToken = await userCredential.getIdToken();
            Cookies.set('authToken', idToken, { domain: '.yugacore.com' });
            navigate('/');
        } catch (error) {
            console.log(error)
        }
    }

    function generateSessionId() {
        const now = new Date();
    
        function generateSessionIdString() {
            return Math.random().toString(36).substring(2, 15);
        }
    
        return `${generateSessionIdString()}-${now.getTime()}`;
    }

    function generateExpireDate() {
        const now = new Date();
        const expirationTime = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth() + 1, now.getUTCDate()));
    
        return expirationTime.getTime();
    }

    async function saveUserData(userCredential, email) {
        async function publicInfo(photoURL) {
            const userRef = doc(usersFirestore, `${userCredential.uid}/public`);
            
            try {
                await setDoc(userRef, {
                    data: [{
                        email: email,
                        name: signUp.name,
                        photoURL: photoURL
                    }]
                });
                return true;
            } catch (error) {
                console.log(error);
                return false;
            }
        }
    
        async function personalInfo(photoURL) {
            const userRef = doc(usersFirestore, `${userCredential.uid}/personal-info`);
    
            try {
                await setDoc(userRef, {
                    data: [{
                        basic: {
                            birthdate: signUp.birthdate,
                            gender: Number(signUp.gender),
                            name: signUp.name,
                            lastName: signUp.lastName,
                            publicName: signUp.name,
                            photoURL: photoURL
                        },
                        contact: {
                            primaryEmail: email,
                            additionalEmails: []
                        }
                    }]
                });
                return true;
            } catch (error) {
                console.log(error);
                return false;
            }
        }
    
        const userStorageRef = ref(usersStorage, `${userCredential.uid}/public/pfp.png`);
    
        const metadata = {
            contentType: 'image/png'
        }
    
        try {
            const snap = await uploadString(userStorageRef, userDefaultPfp, 'data_url', metadata);
            const downloadURL = await getDownloadURL(snap.ref);
            const personalInfoSaved = await personalInfo(downloadURL);
            const publicInfoSaved = await publicInfo(downloadURL);
    
            if (personalInfoSaved && publicInfoSaved) {
                await updateProfile(userCredential, {
                    displayName: signUp.name,
                    photoURL: downloadURL
                })
                await handleCookies(userCredential);
            } else {
                console.error('No se pudo subir la información personal ni la pública.');
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSaveAccount = async (user, email, isLogin, isSync) => {
        const sessionId = localStorage.getItem('sessionId');
        const encryptedUserUid = await encrypt(user.uid);

        if (sessionId) {
            const decryptedSession = await decrypt(sessionId);
            const sessionRef = doc(activeSessionsFirestore, `active_sessions/${decryptedSession}`)

            try {
                await updateDoc(sessionRef, {
                    expire: generateExpireDate() 
                });

                Cookies.set('sessionId', sessionId, { domain: '.yugacore.com' });

                const sessionAccountRef = doc(activeSessionsFirestore, `active_sessions/${decryptedSession}/accounts/list`)
                const sessionAccountSnap = await getDoc(sessionAccountRef);

                const list = sessionAccountSnap.data().data;

                if (list.length > 4) {
                    alert('No puedes vincular mas cuentas a esta sesión. (Límite de 5)')
                    if (!isSync) {
                        if (!isLogin) {
                            await saveUserData(user, email);
                        } else {
                            await handleCookies(user);
                        }
                    }
                    return;
                }

                async function verifyExist() {
                    const checks = await Promise.all(list.map(async (encryptedUid) => {
                        const decryptedUid = await decrypt(encryptedUid);
                        return decryptedUid === user.uid;
                    }))

                    return checks.some(result => result === true)
                }

                const exist = await verifyExist();

                if (!exist) {
                    await updateDoc(sessionAccountRef, {
                        data: arrayUnion(encryptedUserUid) 
                    });
                }

                if (sessionAccountSnap.exists()) {
                    const name = sessionAccountSnap.data().name;
                    
                    const userDataAndPrivacyRef = doc(usersFirestore, `${user.uid}/data-and-privacy`);
                    const userDataAndPrivacySnap = await getDoc(userDataAndPrivacyRef);

                    if (userDataAndPrivacySnap.exists()) {
                        console.log(1)
                        if (userDataAndPrivacySnap.data().sessions) {
                            if (userDataAndPrivacySnap.data().sessions.length > 2) {
                                await updateDoc(userDataAndPrivacyRef, {
                                    sessions: arrayRemove(userDataAndPrivacySnap.data().sessions[0])
                                })
                            }
                        }

                        await updateDoc(userDataAndPrivacyRef, {
                            sessions: arrayUnion({
                                name: name,
                                ownerId: encryptedUserUid,
                                sessionId: sessionId,
                                expired: false,
                                sessionOwnerId: sessionAccountSnap.data().data[0],
                                timestamp: Date.now()
                            })
                        })

                        const userCompleteList = doc(usersFirestore, `${user.uid}/data-and-privacy/sessions/list`);
                        const userCompleteListSnap = await getDoc(userCompleteList);

                        if (userCompleteListSnap.exists()) {
                            await updateDoc(userCompleteList, {
                                data: arrayUnion({
                                    name: name,
                                    ownerId: encryptedUserUid,
                                    sessionId: sessionId,
                                    expired: false,
                                    sessionOwnerId: sessionAccountSnap.data().data[0],
                                    timestamp: Date.now()
                                })
                            })
                        } else {
                            await setDoc(userCompleteList, {
                                data: arrayUnion({
                                    name: name,
                                    ownerId: encryptedUserUid,
                                    sessionId: sessionId,
                                    expired: false,
                                    sessionOwnerId: sessionAccountSnap.data().data[0],
                                    timestamp: Date.now()
                                })
                            })
                        }

                    } else {
                        console.log(2)
                        await setDoc(userDataAndPrivacyRef, {
                            sessions: arrayUnion({
                                name: name,
                                ownerId: encryptedUserUid,
                                sessionId: sessionId,
                                expired: false,
                                sessionOwnerId: sessionAccountSnap.data().data[0],
                                timestamp: Date.now()
                            })
                        })

                        const userCompleteList = doc(usersFirestore, `${user.uid}/data-and-privacy/sessions/list`);
                        const userCompleteListSnap = await getDoc(userCompleteList);

                        if (userCompleteListSnap.exists()) {
                            await updateDoc(userCompleteList, {
                                data: arrayUnion({
                                    name: name,
                                    ownerId: encryptedUserUid,
                                    sessionId: sessionId,
                                    expired: false,
                                    sessionOwnerId: sessionAccountSnap.data().data[0],
                                    timestamp: Date.now()
                                })
                            })
                        } else {
                            await setDoc(userCompleteList, {
                                data: arrayUnion({
                                    name: name,
                                    ownerId: encryptedUserUid,
                                    sessionId: sessionId,
                                    expired: false,
                                    sessionOwnerId: sessionAccountSnap.data().data[0],
                                    timestamp: Date.now()
                                })
                            })
                        }
                    }
                }

                if (!isSync) {
                    if (!isLogin) {
                        await saveUserData(user, email);
                    } else {
                        await handleCookies(user);
                    }
                }

    
            } catch (error) {
                console.log(error);
            }
        } else {
            const id = generateSessionId();

            const sessionRef = doc(activeSessionsFirestore, `active_sessions/${id}`)
    
            try {
                await setDoc(sessionRef, {
                    expire: generateExpireDate() 
                });
    
                const sessionAccountRef = doc(activeSessionsFirestore, `active_sessions/${id}/accounts/list`)
                await setDoc(sessionAccountRef, {
                    name: isLogin ? user.displayName : signUp.name,
                    data: [encryptedUserUid] 
                });

                const userViewDataAndPrivacyRef = doc(usersFirestore, `${user.uid}/data-and-privacy`);
                const userViewDataAndPrivacySnap = await getDoc(userViewDataAndPrivacyRef);

                const encryptedId = await encrypt(id);

                if (userViewDataAndPrivacySnap.exists()) {
                    if (userViewDataAndPrivacySnap.data().sessions) {
                        if (userViewDataAndPrivacySnap.data().sessions.length > 2) {
                            await updateDoc(userViewDataAndPrivacyRef, {
                                sessions: arrayRemove(userViewDataAndPrivacySnap.data().sessions[0])
                            })
                        }
                    }

                    console.log(1)
                    await updateDoc(userViewDataAndPrivacyRef, {
                        sessions: arrayUnion({
                            name: user.displayName,
                            sessionId: encryptedId,
                            ownerId: encryptedUserUid,
                            expired: false,
                            sessionOwnerId: encryptedUserUid,
                            timestamp: Date.now()
                        })
                    })
                } else {
                    console.log(2)
                    await setDoc(userViewDataAndPrivacyRef, {
                        sessions: arrayUnion({
                            name: user.displayName,
                            sessionId: encryptedId,
                            ownerId: encryptedUserUid,
                            expired: false,
                            sessionOwnerId: encryptedUserUid,
                            timestamp: Date.now()
                        })
                    })
                }

                const userDataAndPrivacyRef = doc(usersFirestore, `${user.uid}/data-and-privacy/sessions/list`);
                const userDataAndPrivacySnap = await getDoc(userDataAndPrivacyRef);

                if (userDataAndPrivacySnap.exists()) {
                    console.log(1)
                    await updateDoc(userDataAndPrivacyRef, {
                        data: arrayUnion({
                            name: user.displayName,
                            sessionId: encryptedId,
                            ownerId: encryptedUserUid,
                            expired: false,
                            sessionOwnerId: encryptedUserUid,
                            timestamp: Date.now()
                        })
                    })
                } else {
                    console.log(2)
                    await setDoc(userDataAndPrivacyRef, {
                        data: arrayUnion({
                            name: user.displayName,
                            sessionId: encryptedId,
                            ownerId: encryptedUserUid,
                            expired: false,
                            sessionOwnerId: encryptedUserUid,
                            timestamp: Date.now()
                        })
                    })
                }
    
                localStorage.setItem('sessionId', encryptedId);
                Cookies.set('sessionId', encryptedId, { domain: '.yugacore.com' });

                if (!isSync) {
                    if (!isLogin) {
                        await saveUserData(user, email);
                    } else {
                        await handleCookies(user);
                    }
                }
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleRegisterYuga = async (email, password, saveAccount) => {
        const localEmail = email ? email : signUp.email;
        const localPassword = password ? password : signUp.password;
        const localSaveACcount = saveAccount ? saveAccount : signUp.saveAccount;
    
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, localEmail, localPassword);
    
            if (localSaveACcount) {
                await handleSaveAccount(userCredential.user, localEmail, false);
            } else {
                await saveUserData(userCredential.user, localEmail);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const value = {
        user,
        loginYuga: handleLoginYuga,
        thirdPartyLogin: handleThirdPartyLogin,
        registerYuga: handleRegisterYuga,
        loginCookies: handleCookies,
        saveInBrowser: handleSaveAccount,
        syncWithYuga: handleSyncWithYuga,
        signUp,
        setSignUp,
        signIn,
        setSignIn
    };
    
    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}