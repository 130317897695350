import { doc, getDoc } from 'firebase/firestore'
import { appsFirestore } from '../../../../services/firebase/firebase'

export async function getAppData(aid) {

    const auid = aid === 'Yüga' ? process.env.REACT_APP_APP_DB_ID : aid;
    const sessionData = sessionStorage.getItem(`app-${auid}`);

    if (!aid) return;

    if (sessionData) {
        const data = JSON.parse(sessionData);
        return data;
    } else {
        const appRef = doc(appsFirestore, `apps/${auid}`);
        const appSnap = await getDoc(appRef);

        if (appSnap.exists()) {
            const data = appSnap.data().data[0];
            sessionStorage.setItem(`app-${auid}`, JSON.stringify(data));
            return data;
        } else {
            throw new Error('404');
        }
    }
}
