import React, { useContext, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { AuthContext } from '../../../context/AuthContext';
import YugaLogo from '../../../assets/Logo';
import SyncButton from '../../../components/SyncButton';

export default function VersionLayout() {
    const { user } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if ((user) === null || (!location.pathname.split('/')[2])) {
            navigate(`/v1/signin?origin=zWRPsx1OqWLfBmlKYJLG&continue=${encodeURIComponent('https://myaccount.yugacore.com/')}`);
            return;
        }
    }, [user])

    return (
        <div className='account-chooser_layout'>
            <div className="account">
                <Outlet />
            </div>
            <div className="account-chooser_footer">
                <div className='account-chooser_footer-layout'>
                    <footer>
                        <div className='flex align-center' style={{ gap: '.5rem' }}>
                            <YugaLogo />
                            {(location.pathname.split('/')[2] === 'account-chooser') || (location.pathname.split('/')[2] === 'signin') ?
                                <SyncButton /> : null
                            }
                        </div>
                        <ul>
                            <li>
                                <a href="https://support.yugacore.com/help" target='_blank' rel='noreferrer noopener' className='btn text-lite'>Ayuda</a>
                            </li>
                            <li>
                                <a href="https://policies.yugacore.com/privacy" target='_blank' rel='noreferrer noopener' className='btn text-lite'>Privacidad</a>
                            </li>
                            <li>
                                <a href="https://policies.yugacore.com/terms" target='_blank' rel='noreferrer noopener' className='btn text-lite'>Condiciones</a>
                            </li>
                        </ul>
                    </footer>
                </div>
            </div>
        </div>
    )
}
