import { useEffect } from 'react'

export default function WarningMessage() {

    useEffect(() => {
        const message = `
            %cSi usas esta consola, un atacante podría suplantar tu identidad y robar tu información a través de un ataque llamado self-XSS.
            %cNo ingreses ni pegues ningún código que no entiendas.
            %cMás información: https://es.wikipedia.org/wiki/Self-XSS
        `;

        console.log(
            '%c ¡Advertencia!',
            'color: red; background-color: yellow; text-transform: uppercase; font-size: 20px; font-weight: bold;'
        )
        console.log(
            message,
            'color: #FFFF55; font-size: 14px;', // estilos para el texto del cuerpo del mensaje
            'color: red; font-size: 14px; font-style: italic;',
            'color: inherit; font-size: 14px; font-style: italic;' // estilos para el enlace
        );
    }, []);

    return null;
}
