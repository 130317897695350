import React, { useContext, useEffect } from 'react'
import { AuthContext } from '../context/AuthContext'
import { Outlet, useNavigate } from 'react-router-dom';

export default function Home() {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (user === null) {
            navigate(`/v1/account-chooser/identifier?origin=${encodeURIComponent('Yüga')}&continue=${encodeURIComponent('https://accounts.yugacore.com')}`);
            return;
        } 

        if (user) {
            window.location.href = 'https://myaccount.yugacore.com'
        }

    }, [user])

    return <Outlet />
}
