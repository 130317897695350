import { Close } from '@mui/icons-material';
import { IconButton, LinearProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import './Modal.scss';

export default function Modal({ open, close, title, children, loading }) {
    const [ modalOpen, setModalOpen ] = useState(false);
    const [ modalState, setModalState ] = useState(false);

    useEffect(() => {
        if (open) {
            setModalOpen(true);
            setTimeout(() => {
                setModalState(true);
            }, 50);
        } else if (!open) {
            setModalState(false);
            setTimeout(() => {
                setModalOpen(false);
            }, 200)
        }
    }, [open])

    if (!modalOpen) return;

    return (
        <>
        {createPortal(
            <div className={`modal ${modalState && 'show'} ${loading && 'loading'}`}>
                <div className='backdrop' onClick={close}></div>
                <div className="modal_container">
                    <div className="scroller">
                        <div className="loading-interface">
                            {loading && <LinearProgress className='progress linear' />}
                        </div>
                        <div className="modal_container-title">
                            <h2>{title}</h2>
                            <IconButton onClick={close}>
                                <Close />
                            </IconButton>
                        </div>
                        <div className='modal_container-content'>
                            {children}
                        </div>
                    </div>
                </div>
            </div>,
            document.body
        )}
        </>
    )
}
