import React from 'react'

export default function AccountFooter({
    brand,
}) {
    return (
        <div className='account-chooser_footer'>
            <div>
                {`Para continuar, Yüga compartirá tu nombre, dirección de correo electrónico y foto de perfil con ${brand.name}. Antes de usar ${brand.name}, revisa su `}
                <a href={brand.policies.privacy} target='_blank'rel='noreferrer noopener' className='btn text-lite'>política de privacidad</a> y <a href={brand.policies.terms} target='_blank'rel='noreferrer noopener' className='btn text-lite'>condiciones del servicio</a>. 
            </div>
        </div>
    )
}
