import React, { useContext, useEffect, useState } from 'react'
import AccountBanner from '../../../../components/AccountBanner'
import { Button, TextField } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../../../context/AuthContext';

export default function Name() {
    const navigate = useNavigate();
    const location = useLocation();

    const { signUp, setSignUp } = useContext(AuthContext);

    const [ loading, setLoading ] = useState(false);
    const [ name, setName ] = useState(signUp.name);
    const [ lastName, setLastName ] = useState(signUp.lastName);

    const [ errorName, setErrorName ] = useState('');

    function handleErrorMessage() {
        if (errorName === 'required') return 'El campo es obligatorio.';
        if (errorName === 'tooLess') return 'El nombre es demasiado corto.'
        if (errorName === 'tooLong') return 'El nombre es demasiado largo.'
    }

    const handleFormName = () => {
        setLoading(true);
        setSignUp({ ...signUp, name: name, lastName: lastName })
        setTimeout(() => {
            const newPath = location.pathname.replace('/name', '/basic-info');
            navigate(`${newPath}${location.search}`);
            setLoading(false);
        }, 500)
    }

    useEffect(() => {
        if (name.length > 50) {
            setErrorName('tooLong')
        } else {
            if (name.length < 3 && name.length > 0) { 
                setErrorName('tooLess') 
            } else { 
                setErrorName('') 
            }
        }
        if ((errorName === 'required' && name.length > 0)) {
            setErrorName('')
        }
    }, [name, errorName])

    return (
        <form 
            className={`account-inherit ${loading && 'loading'}`}
            onSubmit={(e) => {
                e.preventDefault();
                handleFormName();
            }}
        >
            <AccountBanner 
                submitting={loading}
                isYuga={true}
                brand={{
                    logo: 'https://i.pinimg.com/564x/a2/4e/db/a24edb6c22d718dc553dfaddac887ee0.jpg',
                    name: "Yüga",
                    email: 'pjr-accounts-unblock-rg@riotgames.com',
                    redirect: 'https://authenticate.riotgames.com',
                }}
                type="signup"
                title="Crea una Cuenta de Yüga"
                desc="Ingresa tu nombre"
            >
                <div
                    className='flex flow-column'
                    style={{ gap: '1rem'}}
                >
                    <TextField 
                        className='full-width'
                        label="Nombre"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                        error={errorName !== ''}
                        helperText={errorName !== '' && handleErrorMessage()}
                        onInvalid={(e) => setErrorName('required')}
                    />
                    <TextField 
                        className='full-width'
                        label="Apellido (opcional)"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </div>
                <div className='buttons flex justify-end' style={{ margin: 'auto 0 0 -1rem', paddingTop: '2rem'}}>
                    <Button type='submit' className='btn high'>
                        <span>Siguiente</span>
                    </Button>
                </div>
            </AccountBanner>
        </form>
    )
}
